<template>
  <div id="strobeControl">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      <el-breadcrumb-item>道闸控制记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              @change="parkingLotChange"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择出入口:</span>
            <el-select
              v-model="filtrate.channelId"
              filterable
              clearable
              placeholder="请选择"
              @change="channelChange"
            >
              <el-option
                v-for="item in passagewayList"
                :key="item.channelId"
                :label="item.channelName"
                :value="item.channelId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择设备:</span>
            <el-select
              v-model="filtrate.deviceId"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.deviceRegisterId"
                :label="item.deviceName"
                :value="item.deviceRegisterId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开闸结果:</span>
            <el-select
              v-model="filtrate.controlSuccess"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option label="成功" :value="true"></el-option>
              <el-option label="失败" :value="false"></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              :clearable="false"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              :clearable="false"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <ul>
            <li>
              <el-button type="primary" size="medium" @click="getList(1)"
                >查询
              </el-button>
            </li>
            <li>
              <el-button class="resetBtn" size="medium" @click="reset"
                >重置
              </el-button>
            </li>
          </ul>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        :key="table.loading"
      >
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="出入口" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="deviceSn" label="设备SN" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="controlSuccess" label="开闸结果" align="center">
        </el-table-column>
        <el-table-column prop="failedReason" label="失败原因" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  watch: {
    parkingLotList: {
      handler(list) {
        if (list.length) {
          Object.assign(this.filtrate, {
            parkingLotId: list[0].parkingLotId,
          });
          this.parkingLotChange();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      passagewayList: [],
      deviceList: [],
      filtrate: {
        keyword: "",
        parkingLotId: "",
        deviceId: "",
        channelId: "",
        controlSuccess: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getDeviceList();
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
        parkingLotId: this.parkingLotList.length
          ? this.parkingLotList[0].parkingLotId
          : "",
        channelId: "",
        deviceId: "",
        controlSuccess: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      };
      this.getPassagewayList();
      this.getList(1);
    },
    parkingLotChange() {
      Object.assign(this.filtrate, {
        deviceId: null,
        channelId: null,
      });
      this.getPassagewayList();
      this.getDeviceList();
      this.getList(1);
    },
    channelChange() {
      Object.assign(this.filtrate, {
        deviceId: null,
      });
      this.getDeviceList();
      this.getList(1);
    },
    async getPassagewayList() {
      try {
        let res = await this.$http.post("/channel/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.passagewayList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getDeviceList() {
      try {
        let res = await this.$http.post("/device/register/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
          channelId: this.filtrate.channelId ? this.filtrate.channelId : null,
        });
        if (res.code === 0) {
          this.deviceList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/log/strobe/control", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
          channelId: this.filtrate.channelId ? this.filtrate.channelId : null,
          deviceId: this.filtrate.deviceId ? this.filtrate.deviceId : null,
          controlSuccess:
            typeof this.filtrate.controlSuccess === "boolean"
              ? this.filtrate.controlSuccess
              : null,
        });

        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = item.createAt
              ? this.$moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")
              : "--";
            item.controlSuccess = item.controlSuccess ? "成功" : "失败";
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
